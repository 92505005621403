body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fffd5f;
  padding-bottom: 100px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  max-width: 1200px;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.center {
  text-align: center;
  margin: auto;
}

.highlight{
  background-color: #282828;
  color: #fff;
  border-radius: 3px;
  padding: 2px 5px;
}

.description {
  /* font-weight: bold; */
  max-width: 620px;
  margin: auto;
  line-height: 24px;
}

.note {
  font-style: italic;
  max-width: 400px;
  margin: 30px auto;
}

.pure-input {
  padding: 25px!important;
}

.pure-button {
  padding: 15px;
}

#logarea {
  background-color: lightgray;
  min-height: 200px;
  padding: 5px 15px;
  overflow: auto;
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
} */
